import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import BaseLayout from '../components/templates/BaseLayout';
import PrimaryHeader from '../components/atoms/typography/PrimaryHeader';
import SubHeader from '../components/atoms/typography/SubHeader';
import Paragraph from '../components/atoms/typography/Paragraph';
import Headline from '../components/atoms/typography/Headline';
import Spacer from '../components/atoms/utility/Spacer';
import DownloadAppCTA from '../components/atoms/callouts/DownloadAppCTA';
import SEO from '../components/atoms/utility/seo';
import DottedTrail from '../components/atoms/icons/DottedTrail';

export default function HomePage({
    data: { communityImg, exploreImg, inspireImg, dottedLineAvatarImg },
}) {
    return (
        <BaseLayout heroContent={HomePageHeroContent} isHomePage={true}>
            <SEO
                title="Floxk Mobile App - Official Site"
                keywords={[
                    'mobile app',
                    'influencer',
                    'community',
                    'exploration',
                    'experience',
                    'inspiration',
                    'Fetch.ai app',
                ]}
                description={`The Fetch.ai Mobile App is a platform for discovering new places and hidden gems through short-form videos from hobbyists, adventurers, and thrill-seekers who know their area best.`}
            />
            <Spacer />
            <div className="mx-auto text-left sm:text-center max-w-3xl">
                <Headline
                    headerText="What is the Fetch.ai App?"
                    className="mb-4 text-center"
                />
                <Paragraph
                    paragraphText="Fetch.ai App connects you to local communities through your favorite experiences, enabling you to build local relationships for unique content, increased engagement, and ideas for your next adventure."
                    className="max-w-xl lg:max-w-full mx-auto text-center"
                />
            </div>
            <div className="-mx-4 xl:-mx-[100px]">
                <GatsbyImage
                    image={getImage(dottedLineAvatarImg)}
                    alt="A dotted line connecting people avatars"
                />
            </div>
            <Spacer />
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 items-center">
                <div className="mb-10 lg:mb-0 lg:col-span-6 mx-auto lg:mx-0">
                    <Headline
                        spanText="Connecting"
                        spanColor="#7593FF"
                        headerText="Communities"
                        className="mb-4 text-center lg:text-left"
                    />
                    <Paragraph
                        paragraphText="Be your authentic self and meet like-minded people through local groups and events in your hometown or while traveling."
                        className="max-w-xl text-center lg:text-left"
                    />
                </div>
                <div className="relative lg:col-span-6 justify-self-end mx-auto lg:mx-0">
                    <div className="hidden sm:block absolute -top-6 -right-6 opacity-50">
                        <GatsbyImage
                            image={getImage(communityImg)}
                            alt="People playing basketball in a community."
                            imgClassName="shadow-md"
                            className="shadow-lg rounded-3xl"
                        />
                    </div>
                    <div>
                        <GatsbyImage
                            image={getImage(communityImg)}
                            alt="People playing basketball in a community."
                            imgClassName="shadow-md"
                            className="shadow-lg rounded-3xl"
                        />
                    </div>
                </div>
            </div>
            <Spacer />
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 items-center">
                <div className="relative order-2 lg:order-1 lg:mb-0 lg:col-span-6 mx-auto lg:mx-0">
                    <div className="hidden sm:block absolute -top-6 -left-6 opacity-50">
                        <GatsbyImage
                            image={getImage(exploreImg)}
                            alt="People playing basketball in a community."
                            className="shadow-lg rounded-3xl"
                        />
                    </div>
                    <div>
                        <GatsbyImage
                            image={getImage(exploreImg)}
                            alt="People playing basketball in a community."
                            className="shadow-lg rounded-3xl"
                        />
                    </div>
                </div>
                <div className="ml-auto lg:order-2 lg:col-span-6 justify-self-end mx-auto lg:mx-0 mb-10 lg:mb-0">
                    <Headline
                        spanText="Explore"
                        spanColor="#FF986C"
                        headerText="New Places"
                        className="mb-4 text-center lg:text-left"
                    />
                    <Paragraph
                        paragraphText="Discover new places and hidden gems through short-form videos from hobbyists, adventurers, and thrill-seekers who know their area best."
                        className="max-w-lg text-center lg:text-left"
                    />
                </div>
            </div>
            <Spacer />
            <div className="relative z-20 grid grid-cols-1 lg:grid-cols-12 gap-4 items-center">
                <div className="mb-10 lg:mb-0 lg:col-span-6 mx-auto lg:mx-0">
                    <Headline
                        spanText="Experiences"
                        spanColor="#FF7575"
                        headerText="that Inspire"
                        className="mb-4 text-center lg:text-left"
                    />
                    <Paragraph
                        paragraphText="Capture your favorite experiences and get inspired by interacting with real world events and communities."
                        className="max-w-xl text-center lg:text-left"
                    />
                </div>
                <div className="relative lg:col-span-6 justify-self-end mx-auto lg:mx-0">
                    <div className="hidden sm:block absolute -top-6 -right-6 opacity-50">
                        <GatsbyImage
                            image={getImage(inspireImg)}
                            alt="People playing basketball in a community."
                            className="shadow-lg rounded-3xl"
                        />
                    </div>
                    <div>
                        <GatsbyImage
                            image={getImage(inspireImg)}
                            alt="People playing basketball in a community."
                            className="shadow-lg rounded-3xl"
                        />
                    </div>
                </div>
            </div>
            <Spacer />
            <div className="absolute hidden lg:block lg:-right-[1100px] bottom-[-62px]">
                <DottedTrail />
            </div>
            {/* <div className="mx-auto text-left sm:text-center max-w-3xl mb-4 lg:mb-12">
                <Headline
                    spanText="Download the App?"
                    spanColor="#6A67FF"
                    headerText=""
                    className="mb-4 text-center"
                />
                <Paragraph
                    paragraphText="Join the community, start finding friends, and start living new experiences today."
                    className="max-w-xl mx-auto text-center"
                />
            </div>
            <div className="relative z-10">
                <DownloadAppCTA containerStyles="justify-center" />
            </div> */}
            <Spacer />
            <div className="absolute -bottom-[120px] lg:-bottom-[210px] -left-[350px] md:-left-[300px] xl:-left-[400px]">
                <DottedTrail />
            </div>
        </BaseLayout>
    );
}

function HomePageHeroContent() {
    return (
        <>
            <PrimaryHeader
                headerText="Experiences that Matter to You"
                className="mb-2 sm:mb-4 md:mb-8 lg:mb-10 xl:mb-12 max-w-[300px] sm:max-w-none xl:max-w-lg mx-auto xl:mx-0 text-white"
            />
            <SubHeader
                headerText="Make Friends. Explore Places. Create Memories."
                className="mb-4 md:mb-8 lg:mb-10 xl:mb-12 max-w-sm xl:max-w-xl mx-auto sm:mx-0 text-white"
            />
            {/* <DownloadAppCTA containerStyles="justify-center sm:justify-start" />
            <p className="font-light sm:text-lg md:text-xl lg:text-2xl text-white">
                Available in the US
            </p> */}
        </>
    );
}

export const query = graphql`
    query HomePageQuery {
        communityImg: file(name: { eq: "connecting-communities" }) {
            childImageSharp {
                gatsbyImageData(width: 550)
            }
        }
        exploreImg: file(name: { eq: "explore-places" }) {
            childImageSharp {
                gatsbyImageData(width: 550)
            }
        }
        inspireImg: file(name: { eq: "experiences-inspire" }) {
            childImageSharp {
                gatsbyImageData(width: 550)
            }
        }
        dottedLineAvatarImg: file(name: { eq: "home-page-dotted-line" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;
