import React from 'react';

export default function DottedTrail() {
    return (
        <svg
            width="1473"
            height="969"
            viewBox="0 0 1473 969"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M53.125 1C-4.58921 162.631 -81.9747 494 245.316 475.115C669.554 450.637 647.682 737.716 771.537 851.675C926.505 994.262 1125.48 1030.62 1472.11 820.25"
                stroke="black"
                stroke-opacity="0.2"
                stroke-dasharray="16 16"
            />
        </svg>
    );
}
