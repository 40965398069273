import React from 'react';

export default function Headline({
    headerText,
    className,
    spanText,
    spanColor = '#6A67FF',
}) {
    const styles = 'text-xl sm:text-3xl md:text-4xl lg:text-5xl text-light-grey font-light';

    return (
        <h3 className={styles + ' ' + className}>
            <span style={{ color: spanColor }}>{spanText}</span> {headerText}
        </h3>
    );
}
